import { LazyObserver } from "cadfem-modules/src/classes";


(new LazyObserver({
    selector:'.lzy_img',
    activeClass:'lzy_img',
    observe: ({target, IO, activeClass}) => {
      target.src = target.dataset.src;
      const img = new Image(100, 200);
      img.src = target.dataset.src;
      if (target.dataset.srcset) {
        target.srcset = target.dataset.srcset;
        img.srcset = target.dataset.srcset;
      }
      img.onload = () => {
        target.classList.remove(activeClass);
        IO.unobserve(target);
      };
    }
  })
).init();
