import { LazyObserver } from "cadfem-modules/src/classes";


(new LazyObserver({
  selector:'.lzy_bg',
  activeClass:'lzy_bg',
  observe: ({target, IO, activeClass}) => {
    target.style.backgroundImage = `url('${target.dataset.bg}')`;
    target.classList.remove(activeClass);
    IO.unobserve(target);
  }
})).init();
